import { mobile } from "components/templates/Breakpoints";
import { Anchor } from "components/atoms/Anchor";
import { P1 } from "components/atoms/Paragraph";
import { FC, PropsWithChildren, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { color } from "styles/colours";
import { fonts } from "@styles";
import { convertNewlinesToBreaks } from "utils/string";
import { H2 } from "components/atoms/Heading";
import { MobileOnly, NotMobile } from "@yu-life/yulife-components-static";

interface NewZigZagProps {
  title: string | ReactElement;
  description: ReactNode;
  sectionTitle?: string;
  eyebrow?: string;
  linkUrl?: string;
  linkText?: string;
  reverse?: boolean;
  align?: "left" | "right";
  pt?: string;
  pb?: string;
  textTop?: boolean;
  maxWidth?: string;
  footnote?: string;
  paragraphpb?: string;
  centerImg?: boolean;
  eyebrowPb?: string;
  eyebrowPt?: string;
  eyebrowColor?: string;
  wrapperWidth?: string;
}

export const NewZigZag: FC<PropsWithChildren<NewZigZagProps>> = ({
  eyebrow,
  title,
  description,
  sectionTitle,
  linkUrl,
  linkText,
  align,
  reverse,
  children,
  pt,
  pb,
  textTop,
  maxWidth,
  footnote,
  paragraphpb,
  centerImg,
  eyebrowPb,
  eyebrowPt,
  eyebrowColor,
  wrapperWidth,
}) => (
  <>
    {sectionTitle && (
      <NotMobile>
        <SectionTitleWrap>
          <SectionTitle>{convertNewlinesToBreaks(sectionTitle)}</SectionTitle>
        </SectionTitleWrap>
      </NotMobile>
    )}
    <CenteredWrap>
      <Container pt={pt} pb={pb}>
        <NewZigZagWrapper reverse={reverse} centerImg={centerImg}>
          <CopyFullWidth
            textTop={textTop}
            align={align ? align : reverse ? "right" : "left"}
            maxWidth={maxWidth}
          >
            <Wrapper $width={wrapperWidth}>
              {sectionTitle && (
                <MobileOnly>
                  <H2 pt="5rem" pb="2rem" fontSize="2rem">
                    {convertNewlinesToBreaks(sectionTitle)}
                  </H2>
                </MobileOnly>
              )}
              {eyebrow && (
                <Eyebrow pb={eyebrowPb} pt={eyebrowPt} color={eyebrowColor}>
                  {eyebrow}
                </Eyebrow>
              )}
              {typeof title === "string" ? <Title>{convertNewlinesToBreaks(title)}</Title> : title}
              <Description>
                {Array.isArray(description) ? (
                  description.map((p) => (
                    <Paragraph key={p} paragraphpb={paragraphpb}>
                      {p}
                    </Paragraph>
                  ))
                ) : typeof description === "string" ? (
                  <Paragraph paragraphpb={paragraphpb}>
                    {convertNewlinesToBreaks(description)}
                  </Paragraph>
                ) : (
                  <DescriptionJSXWrapper>{description}</DescriptionJSXWrapper>
                )}
              </Description>
              {footnote && <Footnote>{footnote}</Footnote>}
              {linkText && linkUrl && (
                <AnchorWrap>
                  <Anchor color={color.P100} href={linkUrl}>
                    {linkText}
                  </Anchor>
                </AnchorWrap>
              )}
            </Wrapper>
          </CopyFullWidth>
          <JSXFullWidth>{children}</JSXFullWidth>
        </NewZigZagWrapper>
      </Container>
    </CenteredWrap>
  </>
);

const ZIG_ZAG_BREAKPOINT = `@media only screen and (max-width: 78rem)`;

const SectionTitle = styled(H2)`
  max-width: 58rem;
`;

const SectionTitleWrap = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 7.5rem 0 4rem;
`;

const CenteredWrap = styled.div`
  ${ZIG_ZAG_BREAKPOINT} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div<{ pt: string; pb: string }>`
  display: flex;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: ${(p) => p.pt || "3.5rem"};
  padding-bottom: ${(p) => p.pb || "3.5rem"};

  ${ZIG_ZAG_BREAKPOINT} {
    padding: 2rem;
  }
`;

const NewZigZagWrapper = styled.div<{ reverse?: boolean; centerImg?: boolean }>`
  max-width: 100%;
  display: flex;
  align-items: ${(p) => (p.centerImg ? "center" : null)};
  gap: 8rem;
  flex-direction: ${(p) => (p.reverse ? "row-reverse" : "row")};

  ${ZIG_ZAG_BREAKPOINT} {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  ${mobile} {
    gap: 1rem;
  }
`;

const CopyFullWidth = styled.div<{ align: "left" | "right"; textTop?: boolean; maxWidth: string }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.textTop ? "start" : "center")};
  text-align: ${(p) => p.align || "left"};

  ${ZIG_ZAG_BREAKPOINT} {
    flex-direction: column;
    max-width: ${(p) => p.maxWidth || "34rem"};
    text-align: left;
  }
`;

const JSXFullWidth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div<{
  $width: string;
}>`
  width: ${(p) => (p.$width ? p.$width : "28rem")};

  ${ZIG_ZAG_BREAKPOINT} {
    width: 100%;
  }

  a {
    font-size: 1.25rem;
  }
`;

const Eyebrow = styled(P1)<{
  pb?: string;
  pt?: string;
  color?: string;
}>`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${(p) => (p.color ? p.color : color.S3100)};
  padding-bottom: ${(p) => (p.pb ? p.pb : "0")};
  padding-top: ${(p) => (p.pt ? p.pt : "1.5rem")};
`;

const Title = styled.h3`
  font-size: 2.5rem;
  line-height: 2.625rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  ${mobile} {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
  }
`;

const Description = styled.div`
  line-height: 2rem;
  letter-spacing: 0.075rem;
`;

const Paragraph = styled(P1)<{ paragraphpb?: string }>`
  padding-top: 0;
  line-height: 1.9rem;
  padding-bottom: ${(p) => p.paragraphpb || "2rem"};
`;

const Footnote = styled(P1)`
  padding-top: 0;
  padding-bottom: 2rem;
  font-size: 1rem;
  color: ${color.B2100};

  ${mobile} {
    line-height: 1.75rem;
  }
`;

const DescriptionJSXWrapper = styled.div`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  color: ${color.B1100};
  padding-bottom: 1.5rem;
`;

export const AnchorWrap = styled.div`
  margin-bottom: 1.25rem;
  letter-spacing: 0.075rem;
`;
